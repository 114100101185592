import { TrackGoogleAnalyticsPageView } from "./analytics/ga4Tracking";

const essentialQuerystringParams = ["page"];
const thankYouPathSegmentOptions = ["contact", "code-example", "unsubscribe", "newsletter", "feedback"];

export class PageProperties {
    readonly path: string
    readonly pathSegments: string[] | undefined;
    readonly pathLastSegment: string | undefined;

    readonly querystring: string | undefined;
    readonly essentialQuerystring: string | undefined;
    readonly segments: string | undefined;

    readonly pathAndQuery: string;
    readonly pathAndEssentialQuery: string;

    readonly isThankYou: boolean;

    constructor(fields: {  path: string, querystring?: string | undefined, segments?: string | undefined }) {
        this.path = fields.path.toLowerCase();
        this.querystring = fields.querystring;
        this.segments = fields.segments;

        // Get path segments.
        var pathForSegment = this.path;

        if (pathForSegment) {
            if (pathForSegment.substring(0, 1) === "/") {
                // Remove forward slash from beginning of URL
                pathForSegment = pathForSegment.substring(1, pathForSegment.length);
            }
            if (pathForSegment.substring(pathForSegment.length - 1, pathForSegment.length) === "/") {
                // Remove forward slash from beginning of URL
                pathForSegment = pathForSegment.substring(0, pathForSegment.length - 1);
            }
        }

        this.pathSegments = pathForSegment.length > 0 ? pathForSegment.split("/") : undefined;

        if (this.pathSegments && this.pathSegments.length > 0) {
            this.pathLastSegment = this.pathSegments[this.pathSegments.length - 1];
        }

        // Work out essential querystring parameters.
        if (this.querystring) {
            var querystringForSegment = this.querystring;

            if (querystringForSegment && querystringForSegment.substring(0, 1) == "?") {
                // Remove ? at beginning of querystring
                querystringForSegment = querystringForSegment.substring(1, querystringForSegment.length);
            }

            // Split the querystrings up with the '&' symbol.
            var querystringSegments = querystringForSegment ? querystringForSegment.split("&") : undefined;

            if (querystringSegments) {
                for (var tt=1; tt<= querystringSegments.length; tt++) {
                    // For each querystring segment, split with the equals sign.
                    var querystringSegment = querystringSegments[tt-1].split("=");
                    var querystringKey = querystringSegment.length >= 1 ? querystringSegment[0] : undefined;
                    var querystringValue = querystringSegment.length >= 2 ? querystringSegment[1] : undefined;

                    if (querystringKey && querystringValue && essentialQuerystringParams.indexOf(querystringKey) >= 0) {
                        if (!this.essentialQuerystring) {
                            this.essentialQuerystring = "?";
                        }
                        else {
                            this.essentialQuerystring += "&";
                        }
                        this.essentialQuerystring += querystringKey + "=" + querystringValue;
                    }
                }
            }
        }

        // Map paths.
        this.pathAndQuery = this.path + (this.querystring ? this.querystring : "") + (this.segments ? this.segments : "");
        this.pathAndEssentialQuery = this.path + (this.essentialQuerystring ? this.essentialQuerystring : "") + (this.segments ? this.segments : "");

        // Check thank you page.
        this.isThankYou = false;
        if (this.pathSegments != null && this.pathSegments.length >= 2 && thankYouPathSegmentOptions.indexOf(this.pathSegments[this.pathSegments.length - 2]) >= 0 && this.pathSegments[this.pathSegments.length - 1] == "thank-you") {
            this.isThankYou = true;
        }                      
    }
}

const GetPage = (url: string) => {
    if (!url) {
        return undefined;
    }

    url = url.replace(/^http(s)?:\/\/([^\/])/ig, "");

    var URL_REGEX = new RegExp(/^([^\?#]+)(\?[^#]+)?(#.*)?$/ig);

    var path = url.replace(URL_REGEX, "$1");
    var querystring = url.replace(URL_REGEX, "$2");
    var segments = url.replace(URL_REGEX, "$3");

    if (path == "") {
        path = "/";
    }

    return new PageProperties({ path, querystring, segments });
}

const GetCurrentUrl = () => {
    return window.location.pathname + window.location.search + (window.location.hash ? (!window.location.hash.match(/#/ig) ? "#" : "") + window.location.hash : "");
}

const PushHistoryState = (url: string, replace: boolean = false): void => {
    if (replace) {
        window.history.replaceState(null, document.title, url);
    }
    else {
        window.history.pushState(null, document.title, url);
    }
}

export { GetPage, GetCurrentUrl, PushHistoryState };