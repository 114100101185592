import { IFieldProperties } from "./fieldProperties";

export class ReadOnlyProperties implements IFieldProperties {
    name: string;
    value?: number | string;
    defaultValue?: number | string;
    valid?: boolean;
    errorMessage?: JSX.Element;
    onValidChange?: () => void;
    setErrorMessage(errorMessage: JSX.Element): void {        
    }
    validate(): void {
    }
    resetValue(): void {
    }
    refreshComponentState(): void {
    }

    constructor(fields: { name: string, value?: number | string }) {
        this.name = fields.name;
        this.value = fields.value;
        this.defaultValue = fields.value;
        this.valid = true;
    }

}