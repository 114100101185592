import React from "react";
import Modal from "../modal/modal";
import Form from '../form/form';
import { TextBox, TextBoxProperties } from "../form/textBox";
import { TrackGoogleAnalyticsEvent } from "../analytics/ga4Tracking";

const ContactFormModal: React.FC = () => {

    return <Modal url="contact" bindSelector="[data-contact-modal-show]" modalSelector="[data-contact-modal-container]" container={
        <>
            <div className="title-container">
                <div className="title">
                    <h2>Contact us</h2>
                </div>
            </div>
                <div className="content">
                    <p>Please leave your name, email and any questions or comments about Round The Code in the form below.</p>
                </div>
                <Form fieldProperties={
                    [
                        new TextBoxProperties({ name: "name", id: "contact-modal-name", label: "Name", placeholder: "Your name", htmlTag: "input", className: "form-group col-md-6 col-12", required: true, minLength: 5, maxLength: 100, requiredErrorMessage: <>Please enter your name.</>, minErrorMessage: <>Please enter at least 5 characters.</>, maxErrorMessage: <>Please enter no more than 100 characters.</> }),
                        new TextBoxProperties({ name: "email", id: "contact-modal-email", label: "Email",placeholder: "Your email", htmlTag: "input", className: "form-group col-md-6 col-12",  required: true, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ig, requiredErrorMessage: <>Please enter your email address.</>, patternErrorText: <>Please enter a valid email address.</> }),
                        new TextBoxProperties({ name: "comments", id: "contact-modal-comments", label: "Comments", placeholder: "Your comments", htmlTag: "textarea", className: "form-group col-12", required: true, minLength: 50, maxLength: 1000, requiredErrorMessage: <>Please enter your comments.</>, minErrorMessage: <>Please enter at least 50 characters.</>, maxErrorMessage: <>Please enter no more than 1,000 characters.</> }),
                    ]
                } container={
                    <>
                        <TextBox name="name"></TextBox>
                        <TextBox name="email"></TextBox>
                        <TextBox name="comments"></TextBox>
                    </>
                } submitApiEndpoint="/api/contact/submit" submitLabel="Send email" onSubmitCallback={(json) => {
                    TrackGoogleAnalyticsEvent("contact_form_submit");
                    TrackGoogleAnalyticsEvent('generate_lead', { 
                        'generate_lead_form': 'Contact form'            
                    });                      
                }} thankYouContainer={
                    <>
                        Thank you. We have received your contact request.<br /><br />
                        We aim to answer any questions you have raised as soon as possible.
                    </>
                }></Form>
                

        </>
    }></Modal>

}

export default ContactFormModal;