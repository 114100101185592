import { useEffect, useState } from 'react';
import { CaptureSentryException } from '../../helper';
import OnlineCodeEditorFormProperties from './onlineCodeEditorFormProperties';
import OnlineCodeEditorProperties from './onlineCodeEditorProperties';
import OnlineCodeEditorFormResult from './onlineCodeEditorFormResult';
import { TrackGoogleAnalyticsEvent } from '../../analytics/ga4Tracking';

var first = true;
const OnlineCodeEditorForm: React.FC<OnlineCodeEditorFormProperties> = (onlineCodeFormEditorProperties: OnlineCodeEditorFormProperties) => {

    const [state, setState] = useState<{
        onlineCodeEditorProperties?: OnlineCodeEditorProperties, 
        loading: boolean, 
        code: string,
        successMessage?: JSX.Element,
        errorMessage?: JSX.Element
    }>({
        onlineCodeEditorProperties: undefined,
        loading: false,
        code: '',
        successMessage: undefined,
        errorMessage: undefined
    });

    useEffect(() => {
        fetch(`/api/online-code-editor/properties?UmbracoNodeId=${onlineCodeFormEditorProperties.umbracoNodeId}&UmbracoBlockGridItemContentKey=${onlineCodeFormEditorProperties.umbracoBlockGridItemContentKey}`, {
            method: "GET",
        })
        .then((response) => {
            if (!response || !response.status) {
                // Throw error if no response.
                CaptureSentryException(`The endpoint '/api/online-code-editor/properties?UmbracoNodeId=${onlineCodeFormEditorProperties.umbracoNodeId}&UmbracoBlockGridItemContentKey=${onlineCodeFormEditorProperties.umbracoBlockGridItemContentKey}' did not return a response`);
                return false;
            }

            if (!response.ok) {
                // Throw the error response.
                CaptureSentryException(`The endpoint '/api/online-code-editor/properties?UmbracoNodeId=${onlineCodeFormEditorProperties.umbracoNodeId}&UmbracoBlockGridItemContentKey=${onlineCodeFormEditorProperties.umbracoBlockGridItemContentKey}' threw an error: (${response.status}) ${response.statusText}`);
                return false;
            }

            return response.json();
        })
        .then(async(onlineCodeEditorProperties:OnlineCodeEditorProperties) => {
            if (!onlineCodeEditorProperties) {
                CaptureSentryException(`The endpoint '/api/online-code-editor/properties?UmbracoNodeId=${onlineCodeFormEditorProperties.umbracoNodeId}&UmbracoBlockGridItemContentKey=${onlineCodeFormEditorProperties.umbracoBlockGridItemContentKey}' response could not be converted to a 'OnlineCodeEditorProperties' type`);
                return false;
            }

            setState({ onlineCodeEditorProperties: onlineCodeEditorProperties, loading: state.loading, code: state.code, successMessage: undefined, errorMessage: undefined });        
            
            if (window.Prism) {
                let prismCodeElements = document.querySelectorAll(`[data-online-code-editor-container][data-umbraco-block-grid-item-content-key='${onlineCodeEditorProperties?.umbracoBlockGridItemContentKey}'] pre code`);

                if (prismCodeElements) {
                    for (var tt=1; tt<= prismCodeElements.length; tt++) {
                        var prismCodeElement = prismCodeElements[tt-1];

                        window.Prism.highlightElement(prismCodeElement);
                    }
                }    
            }  

            TrackGoogleAnalyticsEvent("online_code_editor_load", {
                "block_grid_item_content_key": onlineCodeEditorProperties.umbracoBlockGridItemContentKey
            }); 

            return true;
        })
        .catch(() => {
            return true;
        });
    }, []);


    var codeSplit = state.onlineCodeEditorProperties?.code?.split(/\[INPUT\]/g);

    if (codeSplit?.length != 2) {
        return;
    }

    var topCode = codeSplit[0];
    var bottomCode = codeSplit[1];

    const onCodeChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {   
        // https://stackoverflow.com/questions/43778468/create-simple-text-editor-javascript
        setState({ onlineCodeEditorProperties: state.onlineCodeEditorProperties, loading: state.loading, code: event.target.value, successMessage: state.successMessage, errorMessage: state.errorMessage });
    };

    const formSubmit = async(event: React.MouseEvent<HTMLButtonElement>) => {

        if (!state.code) {
            setState({ onlineCodeEditorProperties: state.onlineCodeEditorProperties, loading: false, code: state.code, successMessage: undefined, errorMessage: <>Please enter some code.</> });
            await scrollToStatusBar();
            
            return;    
        }

        if (state.code.length > 2000) {
            setState({ onlineCodeEditorProperties: state.onlineCodeEditorProperties, loading: false, code: state.code, successMessage: undefined, errorMessage: <>Your code solution is too long. Please reduce the number of characters in it.</> });
            await scrollToStatusBar();
            
            return;
        }

        setState({ onlineCodeEditorProperties: state.onlineCodeEditorProperties, loading: true, code: state.code, successMessage: undefined, errorMessage: undefined });

        if (!window.onlineCodeEditorExports?.RoundTheCode) {
            var error = true;

            for (var tt=1; tt<= 60; tt++) {
                await new Promise(resolve => setTimeout(function() {
                    resolve(true);
                }, 1000));

                if (window.onlineCodeEditorExports?.RoundTheCode) {
                    error = false;
                    break;
                }
            }

            if (error) {
                CaptureSentryException(`Unable to submit the online code editor as 'window.onlineCodeEditorExports.RoundTheCode' is undefined`);

                setState({ onlineCodeEditorProperties: state.onlineCodeEditorProperties, loading: false, code: state.code, successMessage: undefined, errorMessage: <>Unable to compile your code due to a technical issue. Please try again.</> });
                await scrollToStatusBar();

                return;
            }
        }

        if (!first) {
            // Delay by 500ms so it looks like something is happening.
            await new Promise(resolve => setTimeout(function() {
                resolve(true);
            }, 500));
        }

        let onlineCodeEditorFormResult: OnlineCodeEditorFormResult;
        onlineCodeEditorFormResult = JSON.parse(await window.onlineCodeEditorExports.RoundTheCode.OnlineCodeEditor.Extensions.OnlineCodeEditorExtensions.CompileCodeWithUmbracoAsync(onlineCodeFormEditorProperties.umbracoNodeId, onlineCodeFormEditorProperties.umbracoBlockGridItemContentKey, state.code));

        let successMessage: JSX.Element | undefined = undefined;
        let errorMessage: JSX.Element | undefined = undefined;

        if (!onlineCodeEditorFormResult?.success) {
            if (onlineCodeEditorFormResult?.exceptions?.length ?? 0 > 0) {
                errorMessage = <>The online code editor threw the following exceptions:<br /><br /></>;

                let first = true;
                onlineCodeEditorFormResult.exceptions?.forEach(exception => {
                    if (!first) {
                        errorMessage = <>{errorMessage}<br /></>;
                    }

                    let errorMessageFormat: JSX.Element | undefined = undefined;
                    if (exception.id) {
                        errorMessageFormat = <>{exception.id}: </>
                    }
                    errorMessageFormat = <>{errorMessageFormat}{exception.message}</>

                    errorMessage = <>{errorMessage}<strong>{errorMessageFormat}</strong></>
                    if (first) {
                        first = false;
                    }
                });
            }
            else {
                errorMessage = <>The online code editor successfully compiled but the return value doesn't match the expected value. Please try again.</>
            }   
        }

        if (onlineCodeEditorFormResult.success) {
            successMessage = <>Well done. The online code editor successfully compiled and the return value matches the expected value.</>
        }

        if (first) {
            first = false;
        }

        setState({ onlineCodeEditorProperties: state.onlineCodeEditorProperties, loading: false, code: state.code, successMessage: successMessage, errorMessage: errorMessage });

        TrackGoogleAnalyticsEvent("online_code_editor_build", {
            "block_grid_item_content_key": state.onlineCodeEditorProperties?.umbracoBlockGridItemContentKey
        });

        await scrollToStatusBar();
    };

    const scrollToStatusBar = async() => {
        await new Promise(resolve => setTimeout(function() {
            let headerBar = document.getElementsByTagName("header") as HTMLCollectionOf<HTMLDivElement>;
            let alertBar = onlineCodeFormEditorProperties.element.querySelector(".alert-bar");

            if (alertBar) {
                let alertBarTop = alertBar.getBoundingClientRect().top - 5;

                if (headerBar && getComputedStyle(document.getElementsByTagName("header")[0]).position == "sticky") {
                    alertBarTop -= 70;
                }

                window.scrollTo({ top: alertBarTop + window.scrollY, behavior: 'smooth' });
            }
            resolve(true);
        }));
    };

    return state.onlineCodeEditorProperties ? 
        <div className='container form-container section-container'>
            <div className="header">
                <h2>Online code editor</h2>
            </div> 
            <div className="content">
                <p><strong>Try our online code editor.</strong></p>
                <p className="last">Add your C# code in the white textbox below and press the <strong>Build</strong> button to see if your solution will compile.</p>
                {state.successMessage ?
                    <div className="alert-bar">
                        <div className="alert alert-success">
                            <>{state.successMessage}</>
                        </div>
                    </div>
                : <></>}
                {state.errorMessage ?
                    <div className="alert-bar">
                        <div className="alert alert-danger">
                            <>{state.errorMessage}</>
                        </div>
                    </div>
                : <></>}
                <div className="row">
                    <div className="form-group col-12">
                        <pre className="language-csharp">
                            <code> 
                                {topCode}
                            </code>
                            <code className='editable'>
                                <textarea placeholder='Enter code here...' spellCheck={false} className="form-control" onInput={onCodeChange}></textarea>
                            </code>
                            <code>
                                {bottomCode}
                            </code>
                        </pre>
                    </div>
                </div>
                {state.loading ? 
                    <div className="loader">
                        <div className="loader-container"><div></div><div></div><div></div></div>
                    </div> 
                    : <></>
                }
                <div className="row form-submit">
                    <button className="btn btn-primary" onClick={formSubmit}>Build</button>
                </div>                      
            </div>             
        </div> 
    : 
        <></>
}
export default OnlineCodeEditorForm;