import { CaptureSentryException } from "../helper";
import { GetSiteOptions } from "../siteOptions";

const TrackYouTubePlayToWebsite = (youTubeId: string, youTubeTitle: string) => {
    var options = GetSiteOptions();

    if (!window.YouTubePlayEventTracked) {
        window.YouTubePlayEventTracked = [];
    }    
    window.YouTubePlayEventTracked.push(youTubeId.toString());

    var values: any = {};
    values["youTubeId"] = youTubeId;
    values["youTubeTitle"] = youTubeTitle;

    // Send tracking to website.
    fetch(`/api/youtube/play`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
    })
    .then((response) => {
        if (!response || !response.status) {
            // Throw error if no response.
            CaptureSentryException("The endpoint '/api/youtube/play' did not return a response");
            return false;
        }

        if (!response.ok) {
            // Throw the error response.
            CaptureSentryException("The endpoint '/api/youtube/play' threw an error: (" + response.status + ") " + response.statusText);
            return false;
        }

        return true;
    })
    .catch(() => {        
        return true;
    });

};

export { TrackYouTubePlayToWebsite };