import React, { useContext, useState } from "react";
import { GenerateRandomString } from "../helper";
import { IFieldProperties } from "./fieldProperties";
import { FormContext } from "./form";

export class CheckboxProperties implements IFieldProperties {
    name: string;
    id: string;
    label: JSX.Element;
    className: string;
    value: boolean;
    defaultValue?: boolean;
    required: boolean;
    valid?: boolean;
    errorMessage?: JSX.Element;

    requiredErrorMessage?: JSX.Element;
    onValidated?: (() => void);
    onValidChange?: () => void;
    onResetValue?: (() => void);
    onRefreshComponentState?: (() => void);

    constructor(fields: { name: string, id: string, label: JSX.Element, className: string, defaultValue?: boolean, required?: boolean, requiredErrorMessage?: JSX.Element }) {
        this.id = GenerateRandomString(8) + "-" + fields.id;
        this.name = fields.name;
        this.label = fields.label;     
        this.className = fields.className; 
        this.value = fields.defaultValue ?? false;
        this.defaultValue = fields.defaultValue ?? false;
        this.required = fields.required ?? false;
        this.requiredErrorMessage = fields.requiredErrorMessage;
    }


    validate(): void {
        var originalValid = this.valid;

        this.valid = true;
        this.errorMessage = undefined;

        if (this.required && !this.value) {
            // Required
            this.valid = false;
            this.errorMessage = this.requiredErrorMessage;
        }

        if (this.onValidated) {
            this.onValidated();
        }

        if (this.onValidChange && originalValid !== undefined && originalValid != this.valid) {
            // Revalidate form if the valid status has changed.
            this.onValidChange();
        }
    }
    setErrorMessage(errorMessage: JSX.Element): void {
        this.valid = false;
        this.errorMessage = errorMessage;

        if (this.onValidated) {
            this.onValidated();
        }
    }
    resetValue(): void {
        this.value = false;
        this.valid = undefined;
        this.errorMessage = undefined;

        if (this.onResetValue) {
            this.onResetValue();
        }
    }
    refreshComponentState(): void {
        this.valid = undefined;
        this.errorMessage = undefined;

        if (this.onRefreshComponentState) {
            this.onRefreshComponentState();
        }    
    }

}

const Checkbox: React.FC<{name: string}> = (properties) => {
    const formContext = useContext(FormContext);    
    var checkboxProperties = formContext?.findFieldProperties<CheckboxProperties>(properties.name);

    const [state, setState] = useState<{value: boolean, valid?: boolean, errorMessage?: JSX.Element}>({
        value: checkboxProperties?.value ?? false,
        valid: checkboxProperties?.valid,
        errorMessage: undefined
    });

    if (!checkboxProperties) {
        return <></>
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {   
        if (checkboxProperties) {
            checkboxProperties.value = event.target.checked;

            if (checkboxProperties.valid !== undefined) {
                // Already been validated, so revalid text box. This will call textBoxProperties.onValidated
                checkboxProperties.validate();
            }
            else {
                // Set the value of the textbox only.
                setState({ value: checkboxProperties.value, valid: checkboxProperties.valid, errorMessage: checkboxProperties.errorMessage });
            }
        }
    };    

    checkboxProperties.onValidated = () => {
        if (checkboxProperties) {
            setState({ value: checkboxProperties.value, valid: checkboxProperties.valid, errorMessage: checkboxProperties.errorMessage });
        }
    }
    checkboxProperties.onResetValue = () => {
        if (checkboxProperties) {
            setState({ value: checkboxProperties.value, valid: checkboxProperties.valid, errorMessage: checkboxProperties.errorMessage });
        }
    }  
    checkboxProperties.onRefreshComponentState = () => {
        if (checkboxProperties) {
            setState({ value: checkboxProperties.value, valid: checkboxProperties.valid, errorMessage: checkboxProperties.errorMessage });
        }        
    }  

    return <div className={checkboxProperties.className}>
        <div className="checkbox-container">
            <div className="checkbox-form-container">
                <input type="checkbox" onChange={onChange} id={checkboxProperties.id} name={checkboxProperties.name} required={checkboxProperties.required} value="true" checked={state.value} /> 
            </div>
            {checkboxProperties.label && 
                <div className="checkbox-form-container">
                <label htmlFor={checkboxProperties.id} className="checkbox">{checkboxProperties.label}</label>
                </div>
            }
        </div>
        {state.valid === false &&
            <div className="error-message">
                <>{state.errorMessage}</>
            </div>
        }        
    </div>

}
export { Checkbox }; 