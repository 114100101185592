import { useContext, useEffect, useState } from "react";
import { CaptureSentryException } from "../helper";
import Modal, { ModalContext } from "../modal/modal"
import { GetSiteOptions } from "../siteOptions";
import { CodingChallengeProperties } from "./properties/codingChallengeProperties"

const CodingChallengeAnswerModal: React.FC = () => {

    const options = GetSiteOptions();

    const [state, setState] = useState<{answerHtml: string}>({
        answerHtml: ""
    });

    const LoadForm = (umbracoNodeId: number, attempt: number) => {    
        fetch(`/api/coding-challenge/answer?umbracoNodeId=${umbracoNodeId}`, {
            method: "GET"   
        })
        .then((response) => {
            if (!response || !response.status) {
                // Throw error if no response.
                CaptureSentryException("The endpoint '/api/coding-challenge/answer?umbracoNodeId=" + umbracoNodeId + "' did not return a response");
                return false;
            }

            if (!response.ok) {
                // Throw the error response.
                CaptureSentryException("The endpoint '/api/coding-challenge/answer?umbracoNodeId=" + umbracoNodeId + "' threw an error: (" + response.status + ") " + response.statusText);
                return false;
            }

            return response.json();
        }).then((codingChallengeProperties:CodingChallengeProperties) => {

            if (!codingChallengeProperties) {
                CaptureSentryException("The endpoint '/api/coding-challenge/answer?umbracoNodeId=" + umbracoNodeId + "' response could not be converted to a 'CodingChallengeProperties' type");
                return false;
            }

            setState({ answerHtml: codingChallengeProperties.answer ?? "" });
            
            if (window.Prism) {
                var prismCodeElements = document.querySelectorAll("[data-coding-challenge-answer-modal-container] pre code");

                if (prismCodeElements) {
                    for (var tt=1; tt<= prismCodeElements.length; tt++) {
                        var prismCodeElement = prismCodeElements[tt-1];

                        window.Prism.highlightElement(prismCodeElement);
                    }
                }

            }

            return true;
        })
        .catch(error => {
            if (attempt >= 3) {
                CaptureSentryException(`The endpoint '/api/coding-challenge/answer?umbracoNodeId=${umbracoNodeId}' threw a generic error after 3 attempts: (${error})`);
                return;
            }
  
            LoadForm(umbracoNodeId, attempt + 1);
        });        
    }    

    return <Modal url="answer" bindSelector="[data-coding-challenge-answer-modal-show]" modalSelector="[data-coding-challenge-answer-modal-container]" onShow={() => {
        if (options?.umbracoNodeId && !isNaN(Number(options.umbracoNodeId))) {
            // Call API for the answer
            LoadForm(Number(options.umbracoNodeId), 1);         
        }
    }} container={
        <>
            <div className="title-container">
                <div className="title">
                    <h2>Coding challenge answer</h2>
                </div>
            </div>
            <div className="block-grid-container">
                <div className="block-grid text-block" dangerouslySetInnerHTML={{__html: state.answerHtml}}>

                </div>
            </div>
        </>
    }></Modal>
}

export default CodingChallengeAnswerModal;
