export interface SiteOptions {
    host: string;
    umbracoNodeId?: number;
    currentPageUrl: string;
    termsAndConditionsUrl?: string;
    enableOnlineCodeEditor: boolean;
}

export const GetSiteOptions: SiteOptions | undefined = () => {
    if (!document.getElementById("javaScriptOptions")) {
        return undefined;
    }

    var json = JSON.parse(document.getElementById("javaScriptOptions")!.innerHTML);

    return {
        host: json["Host"] ? json["Host"] : '',
        umbracoNodeId: !isNaN(Number(json["UmbracoNodeId"])) ? Number(json["UmbracoNodeId"]) : undefined,
        currentPageUrl: json["CurrentPageUrl"] ? json["CurrentPageUrl"] : '',
        termsAndConditionsUrl: json["TermsAndConditionsUrl"] ? json["TermsAndConditionsUrl"] : undefined,
        enableOnlineCodeEditor: json["EnableOnlineCodeEditor"] && json["EnableOnlineCodeEditor"] === true ? true : false 
    };
}