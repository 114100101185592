import React from "react";
import Modal from "../modal/modal";
import { TextBox, TextBoxProperties } from "../form/textBox";
import Form from "../form/form";
import { TrackGoogleAnalyticsEvent } from "../analytics/ga4Tracking";
import { LeaveFeedbackFormProperties } from "./properties/leaveFeedbackFormProperties";
import { ReadOnlyProperties } from "../form/readOnly";

const LeaveFeedbackFormModal: React.FC<LeaveFeedbackFormProperties> = (leaveFeedbackFormProperties: LeaveFeedbackFormProperties) => {

    return <Modal url="feedback" bindSelector="[data-leave-feedback-modal-show]" modalSelector="[data-leave-feedback-modal-container]" container={
        <>
            <div className="title-container">
                <div className="title">
                    <h2>Leave feedback</h2>
                </div>
            </div>
                <div className="content">
                    <p>Thank you for taking the time to leave us anonymous feedback.</p>
                    <p>Please leave your comments about Round The Code in the form below.</p>
                </div>
                <Form fieldProperties={
                    [
                        new TextBoxProperties({ name: "comments", id: "leave-feedback-modal-comments", label: "Comments", placeholder: "Your comments", htmlTag: "textarea", className: "form-group col-12", required: true, minLength: 50, maxLength: 1000, requiredErrorMessage: <>Please enter your feedback.</>, minErrorMessage: <>Please enter at least 50 characters.</>, maxErrorMessage: <>Please enter no more than 1,000 characters.</> }),
                        new ReadOnlyProperties({ name: "umbracoNodeId", value: leaveFeedbackFormProperties.umbracoNodeId })            
                    ]
                } container={
                    <>
                        <TextBox name="comments"></TextBox>
                    </>
                } submitApiEndpoint="/api/feedback/submit" submitLabel="Submit your feedback" onSubmitCallback={() => {
                    TrackGoogleAnalyticsEvent("leave_feedback_form_submit");                     
                }} thankYouContainer={
                    <>
                        Thank you for taking the time to leave your feedback.<br /><br />
                        We value your comments and will look to use it in making future decisions.
                    </>
                }></Form>
                

        </>
    }></Modal>

}

export default LeaveFeedbackFormModal;