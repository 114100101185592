import ReactDOM from 'react-dom';
import { GetSiteOptions } from "../../siteOptions";
import OnlineCodeEditorForm from './onlineCodeEditorForm';

const BindOnlineCodeEditor = () => {
    var options = GetSiteOptions();

    if (options?.enableOnlineCodeEditor) {
        var tag = document.createElement('script');
        tag.src = '/online-code-editor/online-code-editor-dotnet-9.js';
        tag.type = "module";
        tag.async = false;
        
        var lastScriptTag = document.getElementsByTagName('script')[document.getElementsByTagName('script').length - 1];
        lastScriptTag.parentNode!.insertBefore(tag, lastScriptTag);  
    }   
};

export { BindOnlineCodeEditor }