import { useState } from "react";
import { CaptureSentryException } from "../helper";
import Modal from "../modal/modal";
import { GetSiteOptions } from "../siteOptions";
import { TermsAndConditionsProperties } from "./properties/termsAndConditionsProperties";

const TermsAndConditionsModal: React.FC = () => {

    var options = GetSiteOptions();

    const [state, setState] = useState<{termsHtml: string}>({
        termsHtml: ""
    });

    const LoadTermsAndConditions = (attempt: number) => {    
        fetch(`/api/terms-conditions`, {
            method: "GET"
        })
        .then((response) => {
            if (!response || !response.status) {
                // Throw error if no response.
                CaptureSentryException("The endpoint '/api/terms-conditions' did not return a response");
                return false;
            }

            if (!response.ok) {
                // Throw the error response.
                CaptureSentryException("The endpoint '/api/terms-conditions' threw an error: (" + response.status + ") " + response.statusText);
                return false;
            }

            return response.json();
        }).then((termsAndConditionsProperties: TermsAndConditionsProperties) => {

            if (!termsAndConditionsProperties) {
                CaptureSentryException("The endpoint '/api/terms-conditions' response could not be converted to a 'TermsAndConditionsProperties' type");
                return false;
            }

            setState({ termsHtml: termsAndConditionsProperties.terms ?? "" });

            return true;
        })
        .catch(error => {
            if (attempt >= 3) {
                CaptureSentryException(`The endpoint '/api/terms-conditions' threw a generic error after 3 attempts: (${error})`);
                return;
            }
  
            LoadTermsAndConditions(attempt + 1);
        });        
    }    

    return <Modal url="terms-conditions" noLoadOnRootUrl={true} bindSelector="[data-terms-and-conditions-modal-show]" modalSelector="[data-terms-and-conditions-modal-container]" mutationBindSelector="[data-code-example-order-form-container]" onShow={() => {
        LoadTermsAndConditions(1);
    }} container={
        <>
            <div className="title-container">
                <div className="title">
                    <h2>Terms and conditions</h2>
                </div>
            </div>
            <div className="block-grid-container">
                <div className="block-grid text-block" dangerouslySetInnerHTML={{__html: state.termsHtml}}>

                </div>
            </div>
        </>
    }></Modal>
}

export default TermsAndConditionsModal;