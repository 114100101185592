import { TrackYouTubePlayToWebsite } from '../analytics/websiteTracking';
import { EscapeAttribute } from '../helper';

const PopulateLoadVideos = () => {

    if (!window.YT || !window.YT.Player) {
        return;
    }

    window.YouTubePlayEventTracked = [];

    var players = [];

    var videoItems = document.querySelectorAll("[data-youtube-id]");

    for (var tt=1; tt<= videoItems.length; tt++) {
        var videoItem = videoItems[tt-1];

        var youTubeId = videoItem.getAttribute("data-youtube-id") != null ? videoItem.getAttribute("data-youtube-id")!.toString() : undefined;

        if (!youTubeId) {
            continue;
        }

        if (videoItem.hasAttribute("data-show-youtube-thumbnail")) {
            // Show YouTube thumbnail
            var youtubeIdName = "youtube-video-" + youTubeId;

            if (document.getElementById(youtubeIdName)) {
                var youTubeIdSelector = document.getElementById(youtubeIdName) as HTMLDivElement;

                if (youTubeIdSelector) {
                    var imageUrl = "https://i.ytimg.com/vi/" + youTubeId + "/maxresdefault.jpg";
    
    
                    var html = "<div class=\"youtube-link-container\">";
                    html += "<span class=\"youtube-play-button\">"
                    html += "<svg height=\"100%\" version=\"1.1\" viewBox=\"0 0 68 48\" width=\"100%\"><path d=\"M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z\"></path><path d=\"M 45,24 27,14 27,34\" fill=\"#fff\"></path></svg>";
                    html += "</span>";
                    html += "<img src=\"" + EscapeAttribute(imageUrl) + "\" alt=\"" + EscapeAttribute(youTubeId) + "\" />";
                    html += "</div>";
    
                    youTubeIdSelector.innerHTML = html;
                                                  
                }                
            }


        }
        else {
            players.push(new window.YT.Player('youtube-video-' + youTubeId, {
                videoId: youTubeId,
                playerVars: {
                    rel: 0,
                    widget_referrer: window.location.href,
                    autoplay: 0,
                    modestbranding: 1,
                    enablejsapi: 1
                },
                events: {
                    'onStateChange': async(event: any) => {
                        var eventPlayTrackCheck = false;

                        var videoData = event.target.getVideoData();

                        if (!videoData) {
                            throw new Error(`Unable to get video data from YouTube for ID '${youTubeId}'`);
                        }

                        var youTubeId = videoData.video_id;
                        var videoTitle = videoData.title;

                        for (var ss=1; ss<=window.YouTubePlayEventTracked.length;ss++) {
                            if (window.YouTubePlayEventTracked[ss-1] == youTubeId) {
                                eventPlayTrackCheck = true;
                                break;
                            }
                        }                        
                                                    
                        if (!eventPlayTrackCheck && event.data == window.YT.PlayerState.PLAYING) {
                            // API call for marking a video as being played.
                            TrackYouTubePlayToWebsite(youTubeId, videoTitle);
                        }
                    }
                }               
            }));  
        }
    }


}

const LoadVideos = () => {
    if (!document.querySelector("[data-youtube-id]")) {
        return;
    }

    if (!window.YT) {
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.async = true;
        tag.onload = (event: Event) => {
            if (window.YT && window.YT.Player) {
                PopulateLoadVideos();
            }
            else {
                window.onYouTubeIframeAPIReady = () => {
                    PopulateLoadVideos();
                };
            }
        };

        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);        
    }
    else {
        PopulateLoadVideos();
    }

}
export default LoadVideos;