import { TrackGoogleAnalyticsEvent } from './analytics/ga4Tracking';
import { CaptureSentryException } from './helper';
import { GetSiteOptions } from './siteOptions';

export const SocialIconsShareLink = () => {

    var socialIconsShareLinks = document.querySelectorAll<HTMLAnchorElement>("a[data-share]");

    if (socialIconsShareLinks) {

        socialIconsShareLinks.forEach(socialIconsShareLink => {
            socialIconsShareLink.addEventListener("click", function(event) {
                // Popup (if enabled)
                if (socialIconsShareLink.hasAttribute("data-popup")) {
                    event.preventDefault();
                    window.open(socialIconsShareLink.getAttribute("href")!.toString(), socialIconsShareLink.getAttribute("data-share")!.toString().toLowerCase(), 'width=800, height=600, allowscrollbars=yes');
                }

                var params: any = {};
                params['link_page_href'] = socialIconsShareLink.getAttribute("href");

                if (socialIconsShareLink.hasAttribute("data-event-type")) {
                    params['type'] = socialIconsShareLink.getAttribute("data-event-type");
                }

                // Track in GA
                TrackGoogleAnalyticsEvent(socialIconsShareLink.getAttribute("data-share")!.toString().toLowerCase() + "_share_click", params);

                // Fire off to website
                TrackToApi(socialIconsShareLink);
                
            });
        });


    }

};

const TrackToApi = (socialIconsShareLink: HTMLAnchorElement) => {
    const options = GetSiteOptions();

    var values: any = {};
    if (options) {
        if (options.umbracoNodeId) {
            values["umbracoNodeId"] = options.umbracoNodeId;
        }
        values["shareType"] = socialIconsShareLink.getAttribute("data-share");

        fetch(`/api/share`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })
        .then((response) => {
            if (!response || !response.status) {
                // Throw error if no response.
                CaptureSentryException("The endpoint '/api/share' did not return a response");
                return false;
            }

            if (!response.ok) {
                // Throw the error response.
                CaptureSentryException("The endpoint '/api/share' threw an error: (" + response.status + ") " + response.statusText);
                return false;
            }

            return true;
        })
        .catch(() => {
            return true;
        });
    }

}
