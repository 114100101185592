import { TrackGoogleAnalyticsEvent } from "../analytics/ga4Tracking";
import { AddClass, RemoveClass } from "../helper";

const SlideMenu = () => {
    if (!document.querySelector("[data-menu-button]") || !document.querySelector("[data-menu-grid]"))  {
        return;
    }

    var dataMenuButton: HTMLDivElement = document.querySelector("[data-menu-button]") as HTMLDivElement;
    var dataMenuGrid: HTMLDivElement = document.querySelector("[data-menu-grid]") as HTMLDivElement;

    var toggleMenuActive = false;
    dataMenuButton.addEventListener("click", async function(event) {
        var isMenuOpen: boolean = dataMenuGrid.classList?.contains("active") ?? false;

        if (toggleMenuActive) {
            return;
        }

        toggleMenuActive = true;
        
        if (!isMenuOpen) {
            // Open menu
            AddClass(dataMenuGrid, "sliding");

            // Set height
            await new Promise(resolve => setTimeout(function() {
                dataMenuGrid.style.height = dataMenuGrid.scrollHeight.toString() + "px";
                resolve(true);
            }, 0));

            // Open menu
            await new Promise(resolve => setTimeout(function() {
                AddClass(dataMenuGrid, "active");
                RemoveClass(dataMenuGrid, "sliding");
                dataMenuGrid.style.removeProperty("height");
                resolve(true);
            }, 350));

            // GA4 event
            TrackGoogleAnalyticsEvent("menu_open", {});
            
        }
        else {
            dataMenuGrid.style.height = dataMenuGrid.scrollHeight.toString() + "px";
            AddClass(dataMenuGrid, "sliding");
            RemoveClass(dataMenuGrid, "active");

            // Remove height which will slide the property
            await new Promise(resolve => setTimeout(function() {
                dataMenuGrid.style.removeProperty("height");
                resolve(true);
            }, 0));

            // Remove sliding
            await new Promise(resolve => setTimeout(function() {
                AddClass(dataMenuGrid, "sliding");

                resolve(true);
            }, 350));     
            
            // GA4 event
            TrackGoogleAnalyticsEvent("menu_close", {});

        }

        toggleMenuActive = false;
    });
}

export default SlideMenu;