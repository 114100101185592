import * as Sentry from "@sentry/react";

const EscapeAttribute = (text: string) => {
    if (!text) {
        return text;
    }

    return text.replace(/\"/ig, "\\\"");

}

const AddClass = (element: HTMLElement, className: string): void => {
    if (!element) {
        return;
    }

    if (!element.classList.contains(className)) {
        element.classList.add(className);
    }   
}

const RemoveClass = (element: HTMLElement, className: string): void => {
    if (!element) {
        return;
    }

    if (element.classList.contains(className)) {
        element.classList.remove(className);
    }   
}

const GenerateRandomString = (length: number): string => {
    let characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

    let result = '';
    let charactersLength = characters.length;
    for (var tt =1; tt<= length; tt++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

const CaptureSentryException = (message: string) => {
    try {
      throw message;
    }
    catch (error) {
      Sentry.captureException(error);
    }
  }



export { EscapeAttribute, AddClass, RemoveClass, GenerateRandomString, CaptureSentryException }