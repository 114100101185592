import { Checkbox, CheckboxProperties } from "../form/checkbox";
import Form from "../form/form";
import { TextBox, TextBoxProperties } from "../form/textBox";
import { ReadOnlyProperties } from "../form/readOnly";
import { CodeExampleOrderFormProperties } from "./properties/codeExampleOrderFormProperties";
import { TrackGoogleAnalyticsEvent } from "../analytics/ga4Tracking";
import { GetCurrentUrl, GetPage } from "../history";
import { GetSiteOptions } from "../siteOptions";

const CodeExampleOrderForm: React.FC<CodeExampleOrderFormProperties> = (codeExampleOrderFormProperties: CodeExampleOrderFormProperties) => {

    if (codeExampleOrderFormProperties) {
        var gaEventParams: any = undefined;
        if (codeExampleOrderFormProperties.ga4EventType) {
            gaEventParams = {"type": codeExampleOrderFormProperties.ga4EventType};
        }

        var options = GetSiteOptions();
    
        var termsAndConditionsLabel: JSX.Element;
        if (options?.currentPageUrl && options.termsAndConditionsUrl) {
            termsAndConditionsLabel = <>Do you agree to the <a data-terms-and-conditions-modal-show="true" href={options.currentPageUrl.replace(/\/$/ig, "") + options.termsAndConditionsUrl}>terms and conditions</a>?</>
        }
        else {
            termsAndConditionsLabel = <>Do you agree to the terms and conditions?</>
        }

        return <div className="section-container">
        <div className="header">
            <h2>Purchase this code example</h2>
        </div>
        <div className="content">  
            <Form fieldProperties={
                [
                    new TextBoxProperties({ name: "email", id: "code-example-order-form-email", label: "Email", placeholder: "Your email", htmlTag: "input", className: "form-group col-12",  required: true, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ig, requiredErrorMessage: <>Please enter your email address.</>, patternErrorText: <>Please enter a valid email address.</> }),
                    new CheckboxProperties({ name: "terms", id: "code-example-order-form-terms", label: <>{termsAndConditionsLabel}</>, className: "form-group col-12",  required: true, requiredErrorMessage: <>Please agree to the terms and conditions.</> }),
                    new ReadOnlyProperties({ name: "umbracoNodeId", value: codeExampleOrderFormProperties.umbracoNodeId })            
                ]
            } container={
                <>
                    <TextBox name="email"></TextBox>
                    <Checkbox name="terms"></Checkbox>
                </>
            } submitApiEndpoint="/api/code-example/order" submitLabel={"Buy for " + codeExampleOrderFormProperties.currencySymbol + codeExampleOrderFormProperties.price.toFixed(2)}
            onRedirectCallback={(json) => {
                TrackGoogleAnalyticsEvent("code_example_order_form_redirect", gaEventParams);
                TrackGoogleAnalyticsEvent('generate_lead', { 
                    'generate_lead_form': 'Code example order form'            
                });
            }}
            ></Form>
        </div> 
    </div>
    }
    return <></>    

}

export default CodeExampleOrderForm;