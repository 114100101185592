import React from "react";
import Modal from "../modal/modal";
import Form from '../form/form';
import { TextBox, TextBoxProperties } from "../form/textBox";
import { GetCurrentUrl, GetPage } from "../history";
import { CaptureSentryException } from "../helper";
import { GetSiteOptions } from "../siteOptions";

const NewsletterUnsubscribeModal: React.FC = () => {

    var options = GetSiteOptions();
    var emailTextBoxProperties = new TextBoxProperties({ name: "email", id: "newsletter-unsubscribe-modal-email", label: "Email",placeholder: "Your email", htmlTag: "input", className: "form-group col-12",  required: true, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ig, requiredErrorMessage: <>Please enter your email address.</>, patternErrorText: <>Please enter a valid email address.</> });

    let currentPage = GetPage(GetCurrentUrl());
    let url = "unsubscribe";
    
    if (currentPage?.pathLastSegment == url) {        
        const getEmail = function(attempt: number) {
            // Call API endpoint to get email address
            let params = new URLSearchParams(window.location.search);
            let id = params.get('id');

            if (!id) {
                return;
            }

            fetch(`/api/newsletter/email?id=${id}`, {
                method: "GET"
            })
            .then((response) => {
                if (!response || !response.status) {
                    // Throw error if no response.
                    CaptureSentryException("The endpoint '/api/newsletter/email?id=" + id + "' did not return a response");
                    return false;
                }

                if (!response.ok) {
                    // Throw the error response.
                    CaptureSentryException("The endpoint '/api/newsletter/email?id=" + id + "' threw an error: (" + response.status + ") " + response.statusText);
                    return false;
                }

                return response.json();
            })                    
            .then(json => {       
                if (!json || !json["email"]) {
                    CaptureSentryException("The endpoint '/api/newsletter/email?id=" + id + "' did not return any JSON or it didn't return an email");
                    return false;
                }

                emailTextBoxProperties.value = json["email"];

                if (emailTextBoxProperties.onRefreshComponentState) {
                    emailTextBoxProperties.onRefreshComponentState();
                }
            })
            .catch(error => {
                if (attempt >= 3) {
                    CaptureSentryException(`The endpoint '/api/newsletter/email?id=${id}' threw a generic error after 3 attempts: (${error})`);
                    return;
                }
      
                getEmail(attempt + 1);
            });
        };
        getEmail(1); 
    }

    return <Modal url={url} bindSelector="[data-newsletter-unsubscribe-modal-show]" modalSelector="[data-newsletter-unsubscribe-modal-container]" container={
        <>
            <div className="title-container">
                <div className="title">
                    <h2>Unsubscribe from the newsletter</h2>
                </div>
            </div>
                <div className="content">
                    <p>To unsubscribe from the newsletter, please enter your email address below.</p>
                </div>
                <Form fieldProperties={
                    [
                        emailTextBoxProperties
                    ]
                } container={
                    <>
                        <TextBox name="email"></TextBox>
                    </>
                } submitApiEndpoint="/api/newsletter/unsubscribe" submitLabel="Unsubscribe" thankYouContainer={
                    <>
                        Thank you.<br /><br />
                        You have successfully unsubscribed from the newsletter.
                    </>
                }></Form>
                

        </>
    }></Modal>

}

export default NewsletterUnsubscribeModal;