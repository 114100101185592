import React, { useContext, useEffect, useRef, useState } from "react";
import { GetPage, GetCurrentUrl, PushHistoryState, PageProperties } from '../history'
import { AddClass, RemoveClass } from '../helper'
import { TrackGoogleAnalyticsPageView } from "../analytics/ga4Tracking";

export class ModalContextProperties {
    url: string;
    visible: boolean;
    goBack: boolean;
    setResetForm?: (onResetForm: (() => void)) => void;
    onStateChange?: () => void;
    onHide?: () => void;

    constructor(fields: { url: string }) {
        this.url = fields.url;
        this.visible = false;
        this.goBack = false;
    }
}

export interface ModalProperties {
    bindSelector: string;
    modalSelector: string;
    url: string;
    noLoadOnRootUrl?: boolean;
    mutationBindSelector?: string | null;
    container: JSX.Element|JSX.Element[];
    onShow?: () => void;
    onResetForm?: () => void;
}

export const ModalContext = React.createContext<ModalContextProperties | undefined>(undefined);

const Modal: React.FC<ModalProperties> = (modalProperties: ModalProperties) => {

    const modalContextProperties = new ModalContextProperties({ url: modalProperties.url });
    modalContextProperties.setResetForm = (onResetForm: (() => void)) => {
        modalProperties.onResetForm = onResetForm;
    }
    modalContextProperties.onStateChange = () => {
        setState({ visible: state.visible, goBack: false });        
    }
    modalContextProperties.onHide = function() {
        hideModal();
    }
       
    const [state, setState] = useState({
        visible: false,
        goBack: false
    });  

    const refStateVisible = useRef(state.visible);
    const refStateGoBack = useRef(state.goBack);
    useEffect(() => {
        refStateVisible.current = state.visible;
        refStateGoBack.current = state.goBack;

        modalContextProperties.visible = state.visible;
        modalContextProperties.goBack = state.goBack;
      }, [state.visible, state.goBack]);

    const bindElementsToOpenModal = (element: HTMLElement | Document) => {     
        // Binds all the elements that will open the modal.
        var items = element.querySelectorAll(modalProperties.bindSelector) as NodeListOf<HTMLButtonElement | HTMLLinkElement | HTMLAnchorElement>;

        if (!items || items.length == 0) {
            return;
        }

        for (var tt=1; tt<=items.length; tt++) {
            var item = items[tt-1];

            // Only bind once.
            if (item.hasAttribute("data-binded")) {
                continue;
            }

            item.setAttribute("data-binded", "true");

            item.addEventListener("click", function(event) {
                // Open the modal.
                showModal(true);                

                // Prevent the default.
                event.preventDefault();
            });
        }
    }

    
    
    

    const backForwardButton = () => {
        window.addEventListener("popstate", (event: PopStateEvent) => {  
            var currentPage = GetPage(GetCurrentUrl());
            var visible = refStateVisible.current;

            if (currentPage && currentPage.pathLastSegment && currentPage.pathLastSegment.toLowerCase() == modalProperties.url.toLowerCase()) {
                // Main modal page

                if (!visible) {
                    // Modal hidden, so make visible
                    showModal(false);
                }
                else {
                    // Modal is already shown so call the handler in the form that resets the form.
                    if (modalProperties.onResetForm) {
                        // Call reset form
                        modalProperties.onResetForm();
                    }
                }
            }

            if (currentPage && currentPage.isThankYou && currentPage.pathSegments && currentPage.pathSegments.length > 1 && currentPage.pathSegments[currentPage.pathSegments.length - 2].toLowerCase() == modalProperties.url.toLowerCase()) {
                // Thank you page - remove thank-you from page.                
                var newPagePath = "/";
                for (var tt=1; tt<= currentPage.pathSegments.length - 1; tt++) {
                    newPagePath += (tt > 1 ? "/" : "") + currentPage.pathSegments[tt-1];
                }
                window.history.back();
            }            
            if (visible && (!currentPage?.pathLastSegment || currentPage.pathLastSegment.toLowerCase() != modalProperties.url.toLowerCase())) {
                // Hide modal if the modal is active, but it doesn't match the URL.
                hideModal(false);
            }
        });
    }

    const mutationBind = () => {
        // Bind links if they are mutated within a particular element
        if (modalProperties.mutationBindSelector) {
            var observer = new MutationObserver((mutations) => {
                mutations.forEach(s => {
                    bindElementsToOpenModal((s.target) as HTMLElement);
                });
            });
            var selector = modalProperties.mutationBindSelector;
            if (selector) {
                var querySelectorAll = document.querySelectorAll(selector);

                querySelectorAll.forEach(querySelector => {
                    if (querySelector) {
                        observer.observe(querySelector, {
                            subtree: true,
                            childList: true
                        });
                    }
                });
            }
        }   
    }

    // Shows the modal
    const showModal = (changePath: boolean) => {

        var currentPage = GetPage(GetCurrentUrl());

        if (changePath) {

            // Formulate new page path
            var newPagePath = "";
            if (currentPage?.pathLastSegment) {
                newPagePath = currentPage?.path;
            }
            // Append modal property URL
            newPagePath += "/" + modalProperties.url;

            PushHistoryState((new PageProperties({ path: newPagePath, querystring: currentPage?.essentialQuerystring })).pathAndEssentialQuery);

            setState({ visible: true, goBack: true });
            if (modalProperties.onShow) {
                modalProperties.onShow();
            }

        }
        else {

            // Does URL match property on page load?
            if (currentPage?.pathLastSegment && currentPage.pathLastSegment.toLowerCase() == modalProperties.url.toLowerCase() && (!modalProperties.noLoadOnRootUrl || (modalProperties.noLoadOnRootUrl && currentPage.path != "/" + modalProperties.url.toLowerCase()))) {
                setState({ visible: true, goBack: false });
                if (modalProperties.onShow) {
                    modalProperties.onShow();
                }
            }
        }
    }

    // Hides the modal
    const hideModal = (changePath: boolean = true) => {

        var currentPage = GetPage(GetCurrentUrl());

        // Populate new page path
        var newPagePath = "/";

        if (currentPage?.pathSegments) {

            var segmentsToRemove = 0;
            if (currentPage.pathLastSegment && currentPage.pathLastSegment == modalProperties.url) {
                // Remove one segment if the last segment is the same as the modal URL
                segmentsToRemove = 1;
            }
            if (currentPage.isThankYou) {
                // Add two segments if it's a thank you page.
                segmentsToRemove = 2;
            }

            for (var tt=1; tt<= currentPage.pathSegments.length - segmentsToRemove; tt++) {
                newPagePath += (tt > 1 ? "/" : "") + currentPage.pathSegments[tt-1];
            }
            if (changePath) {
                if (state.goBack) {
                    window.history.back();
                }
                else {
                    PushHistoryState((new PageProperties({ path: newPagePath, querystring: currentPage?.essentialQuerystring })).pathAndEssentialQuery, currentPage.isThankYou ? true : false);
                }
            }
        }

        // Form is destroyed, so destroy the handler that goes with it.
        if (modalProperties.onResetForm) {
            modalProperties.onResetForm = undefined;
        }

        setState({ visible: false, goBack: false });
    } 

    // When the modal component loads
    useEffect(() => {
        bindElementsToOpenModal(document);
        showModal(state.visible);
        backForwardButton();
        mutationBind();             
    }, []);

    // Display the HTML
    var modal = document.querySelector(modalProperties.modalSelector) as HTMLDivElement;
    var html: HTMLElement = document.getElementsByTagName("html")[0] as HTMLElement;  
    var headerBar: HTMLDivElement = document.getElementsByClassName("header-bar")[0] as HTMLDivElement;

    if (state.visible) {

        if (modal) {
            RemoveClass(modal, "hide");
            AddClass(modal, "show");
        }

        var scrollbarVisible = html.scrollHeight > html.clientHeight;

        // Remove the scrollbar and adjust the right margin.
        if (html) {
            AddClass(html, "hide-scrollbar");

            if (scrollbarVisible) {
                AddClass(html, "adjust-margin");           
            }           
        }
        if (headerBar && scrollbarVisible) {
            AddClass(headerBar, "adjust-margin");                                                            
        }  
    }
    else {
        // Hide the modal.
        const HideModal = async() => {
            if (modal && modal.classList.contains("show")) {
                AddClass(modal, "hide");
                RemoveClass(modal, "show");

                if (html) {
                    RemoveClass(html, "hide-scrollbar");
                    RemoveClass(html, "adjust-margin");
                }
                if (headerBar) {
                    RemoveClass(headerBar, "hide-scrollbar");
                    RemoveClass(headerBar, "adjust-margin");          
                }                 

                await new Promise(resolve => setTimeout(() => {
                    RemoveClass(modal, "hide"); 
                    resolve(true);              
                }, 300));
                        
            } 
        }
        HideModal(); 
    }   
    
    return <ModalContext.Provider value={modalContextProperties}> 
        {state.visible && 
            <div className="modal-container">
                <div className="container-fluid">
                    <div className="close">                
                        <a href="javascript:void(0)" onClick={hideModal}>
                        <svg xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 25 25">
                        <image id="Layer_0" data-name="Layer 0" x="28" y="61" width="20" height="20" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAK0lEQVRIie3NMQEAAAjDsIJ/z0MBJxeNgVSScKyvA0xMTExMdiYmJm8SYABFYAQu1kYLCAAAAABJRU5ErkJggg=="/>
                            <path className="line" d="M-2,.774L0.793-2.02,27,24.19l-0.8,1.719,0.065,0.345-0.345-.065-1.719.8Z"/>
                            <path className="line" d="M24.195-2l2.794,2.794L0.779,27l-1.719-.8-0.345.065,0.065-.345-0.8-1.719Z"/>
                        </svg>	
                        </a>
                    </div>      
                <>{modalProperties.container}</>
            </div>
        </div>  
        }
        {!state.visible && 
            <></>
        }
        </ModalContext.Provider>

}
export default Modal;