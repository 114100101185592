import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import ContactFormModal from './contact/contactFormModal';
import SlideMenu from './menu/menu';
import NewsletterUnsubscribeModal from './newsletter/newsletterUnsubscribeModal';
import LoadVideos from './video/video';
import CodeExampleDownloadForm from './codeExample/codeExampleDownloadForm';
import CodeExampleOrderForm from './codeExample/codeExampleOrderForm';
import { CodeExampleDownloadFormProperties } from './codeExample/properties/codeExampleDownloadFormProperties';
import { CodeExampleOrderFormProperties } from './codeExample/properties/codeExampleOrderFormProperties';
import { BindGoogleAnalyticsEventClicks, BindGoogleAnalyticsTextBlockBlockGridLinks } from './analytics/ga4Tracking';
import { ExternalLinks } from './links';
import { CaptureSentryException } from './helper';
import CodingChallengeAnswerModal from './codingChallenge/codingChallengeAnswerModal';
import TermsAndConditionsModal from './termsAndConditions/termsAndConditionsModal';
import { BindOnlineCodeEditor } from './codingChallenge/onlineCodeEditor/bindOnlineCodeEditor';
import { SocialIconsShareLink } from './socialIcons';
import { GetSiteOptions } from './siteOptions';
import fetch, {
  Headers,
  Request,
  Response,
} from 'node-fetch'
import OnlineCodeEditorForm from './codingChallenge/onlineCodeEditor/onlineCodeEditorForm';
import LeaveFeedbackModal from './leaveFeedback/leaveFeedbackFormModal';

if (!globalThis.fetch) {
  globalThis.fetch = fetch
  globalThis.Headers = Headers
  globalThis.Request = Request
  globalThis.Response = Response
}

// Sentry
Sentry.init({
  dsn: "https://4f13380fd0b5b38b5bfc741c89112ee2@o967394.ingest.sentry.io/4505691793850368",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    }),
  ],    
  // Performance Monitoring
  allowUrls: [/^https:\/\/www\.roundthecode\.com(\/.*)?$/ig],
  tracesSampleRate: 0.2, // Capture 20% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,    
});

new Promise(async resolve => {
  var options = GetSiteOptions();

  if (document.querySelector("[data-code-example-download-form-container]")) {

    let downloadFormUmbracoNodeIds: Number[] = [];  
    document.querySelectorAll("[data-code-example-download-form-container]").forEach(element => {

      var dataUmbracoNodeId = element.getAttribute("data-umbraco-node-id");

      if (!dataUmbracoNodeId) {
        return;
      }

      var umbracoNodeId = Number(dataUmbracoNodeId);

      if (isNaN(umbracoNodeId)) {
        return;
      }

      if (downloadFormUmbracoNodeIds.indexOf(umbracoNodeId) >= 0) {
        return;
      }

      downloadFormUmbracoNodeIds.push(umbracoNodeId);

      const LoadForm = (umbracoNodeId: number, attempt: number) => {
        fetch(`/api/code-example/details?umbracoNodeId=${umbracoNodeId}&isOrderForm=false`, {
            method: "GET"   
        })
        .then((response) => {
            if (!response || !response.status) {
                // Throw error if no response.
                CaptureSentryException("The endpoint '/api/code-example/details?umbracoNodeId=" + umbracoNodeId + "&isOrderForm=false' did not return a response");
                return false;
            }

            if (!response.ok) {
                // Throw the error response.
                CaptureSentryException("The endpoint '/api/code-example/details?umbracoNodeId=" + umbracoNodeId + "&isOrderForm=false' threw an error: (" + response.status + ") " + response.statusText);
                return false;
            }

            return response.json();
        })
        .then((codeExampleDownloadFormProperties: CodeExampleDownloadFormProperties) => {
            if (!codeExampleDownloadFormProperties) {
                CaptureSentryException("The endpoint '/api/code-example/details?umbracoNodeId=" + umbracoNodeId + "&isOrderForm=false' response could not be converted to a 'CodeExampleDownloadFormProperties' type");
                return false;
            }

            document.querySelectorAll("[data-code-example-download-form-container][data-umbraco-node-id='" + codeExampleDownloadFormProperties.umbracoNodeId + "']").forEach(subElement => {
              if (subElement.getAttribute("data-ga4-event-type")) {
                codeExampleDownloadFormProperties.ga4EventType = subElement.getAttribute("data-ga4-event-type")!.toString();
              } 
            
              ReactDOM.render(<CodeExampleDownloadForm {...codeExampleDownloadFormProperties} />, subElement);
            });

            return true;
        })
        .catch(error => {
          if (attempt >= 3) {
            CaptureSentryException(`The endpoint '/api/code-example/details?umbracoNodeId=${umbracoNodeId}&isOrderForm=false' threw a generic error after 3 attempts: (${error})`);
            return;
          }

          LoadForm(umbracoNodeId, attempt + 1);
        }); 
      }
    LoadForm(umbracoNodeId, 1);       


    });
  }

  if (document.querySelector("[data-code-example-order-form-container]")) {

    let orderFormUmbracoNodeIds: Number[] = [];  
    
    document.querySelectorAll("[data-code-example-order-form-container]").forEach(element => {

      var dataUmbracoNodeId = element.getAttribute("data-umbraco-node-id");

      if (!dataUmbracoNodeId) {
        return;
      }

      var umbracoNodeId = Number(dataUmbracoNodeId);

      if (isNaN(umbracoNodeId)) {
        return;
      }

      if (orderFormUmbracoNodeIds.indexOf(umbracoNodeId) >= 0) {
        return;
      }

      orderFormUmbracoNodeIds.push(umbracoNodeId);    

      const LoadForm = (umbracoNodeId: number, attempt: number) => {
        var values: any = {};
        values["umbracoNodeId"] = umbracoNodeId;
        values["isOrderForm"] = true;

        fetch(`/api/code-example/details?umbracoNodeId=${umbracoNodeId}&isOrderForm=true`, {
          method: "GET"   
        })
        .then((response) => {
            if (!response || !response.status) {
                // Throw error if no response.
                CaptureSentryException("The endpoint '/api/code-example/details?umbracoNodeId=" + umbracoNodeId + "&isOrderForm=true' did not return a response");
                return false;
            }

            if (!response.ok) {
                // Throw the error response.
                CaptureSentryException("The endpoint '/api/code-example/details?umbracoNodeId=" + umbracoNodeId + "&isOrderForm=true' threw an error: (" + response.status + ") " + response.statusText);
                return false;
            }

            return response.json();
        }).then((codeExampleOrderFormProperties:CodeExampleOrderFormProperties) => {

            if (!codeExampleOrderFormProperties) {
                CaptureSentryException("The endpoint '/api/code-example/details?umbracoNodeId=" + umbracoNodeId + "&isOrderForm=true' response could not be converted to a 'CodeExampleOrderFormProperties' type");
                return false;
            }

            if (!codeExampleOrderFormProperties.currencySymbol || !codeExampleOrderFormProperties.price) {
                CaptureSentryException("The endpoint '/api/code-example/details?umbracoNodeId=" + umbracoNodeId + "&isOrderForm=true' did not return either a currency symbol or a price.");
                return false;
            }

            document.querySelectorAll("[data-code-example-order-form-container][data-umbraco-node-id='" + codeExampleOrderFormProperties.umbracoNodeId + "']").forEach(subElement => {
              if (subElement.getAttribute("data-ga4-event-type")) {
                codeExampleOrderFormProperties.ga4EventType = subElement.getAttribute("data-ga4-event-type")!.toString();
              }     
              ReactDOM.render(<CodeExampleOrderForm {...codeExampleOrderFormProperties} />, subElement);
            });          

            return true;
        })
        .catch(error => {
          if (attempt >= 3) {
            CaptureSentryException(`The endpoint '/api/code-example/details?umbracoNodeId=${umbracoNodeId}&isOrderForm=true' threw a generic error after 3 attempts: (${error})`);
            return;
          }

          LoadForm(umbracoNodeId, attempt + 1);
        });        
      }  
      LoadForm(umbracoNodeId, 1);  
    });
  }



  var options = GetSiteOptions();

  if (document.querySelector("[data-contact-modal-container]")) {
    ReactDOM.render(<ContactFormModal />, document.querySelector("[data-contact-modal-container]"));
  }
  if (document.querySelector("[data-coding-challenge-answer-modal-container]")) {
    ReactDOM.render(<CodingChallengeAnswerModal />, document.querySelector("[data-coding-challenge-answer-modal-container]"));
  }
  if (document.querySelector("[data-newsletter-unsubscribe-modal-container]")) {
    ReactDOM.render(<NewsletterUnsubscribeModal />, document.querySelector("[data-newsletter-unsubscribe-modal-container]"));
  }
  if (document.querySelector("[data-leave-feedback-modal-container]") && options?.umbracoNodeId) {
    ReactDOM.render(<LeaveFeedbackModal umbracoNodeId={options.umbracoNodeId} />, document.querySelector("[data-leave-feedback-modal-container]"));
  }
  if (document.querySelector("[data-terms-and-conditions-modal-container]")) {
    ReactDOM.render(<TermsAndConditionsModal />, document.querySelector("[data-terms-and-conditions-modal-container]"));    
  }

  SlideMenu();
  LoadVideos(); 

  if (document.querySelector("[data-x-post]")) {
    var tag = document.createElement('script');
    tag.src = "https://platform.twitter.com/widgets.js";
    tag.async = true;

    var lastScriptTag = document.getElementsByTagName('script')[document.getElementsByTagName.length - 1];
    lastScriptTag.parentNode!.insertBefore(tag, lastScriptTag);

    var xPosts = document.querySelectorAll<HTMLElement>("[data-x-post]");

    xPosts.forEach(xPost => {
      xPost.style.display = 'block';
    });
  }

  // External Links
  ExternalLinks(options);

  // Bind GA4 link clicking
  BindGoogleAnalyticsEventClicks(document.getElementsByTagName("body")[0]);
  BindGoogleAnalyticsTextBlockBlockGridLinks();

  // Social Icons
  SocialIconsShareLink();

  // Online code editor
  await BindOnlineCodeEditor();

  if (document.querySelector("[data-online-code-editor-container]")) {
    document.querySelectorAll("[data-online-code-editor-container]").forEach(element => {
      if (!element.getAttribute("data-umbraco-node-id") || !element.getAttribute("data-umbraco-block-grid-item-content-key")) {
        return;
      }

      var umbracoNodeId = Number(element.getAttribute("data-umbraco-node-id")) ?? undefined;
      var umbracoBlockGridItemContentKey = element.getAttribute("data-umbraco-block-grid-item-content-key") ?? undefined;

      if (isNaN(umbracoNodeId)) {
        return;
      }
      if (!umbracoBlockGridItemContentKey) {
        return;
      }

        ReactDOM.render(<OnlineCodeEditorForm { ... {element: element, umbracoNodeId: umbracoNodeId, umbracoBlockGridItemContentKey: umbracoBlockGridItemContentKey } } />, element);        
    });
  }

  resolve(true);
  
});

