import React, { useContext, useEffect, useState } from "react";
import { IFieldProperties } from '../form/fieldProperties';
import { GetCurrentUrl, GetPage, PageProperties, PushHistoryState } from "../history";
import Loader from '../loader/loader';
import { ModalContext } from "../modal/modal";
import { TrackGoogleAnalyticsPageView, TrackGoogleAnalyticsEvent } from '../analytics/ga4Tracking';
import { AddClass, CaptureSentryException, RemoveClass } from "../helper";
import { FormResponse } from '../form/properties/formResponse';
import { GetSiteOptions } from "../siteOptions";

class FormContextProperties {
    fieldProperties?: IFieldProperties[];

    constructor(fields: { fieldProperties?: IFieldProperties[]; }) {
        this.fieldProperties = fields.fieldProperties;
    }

    findFieldProperties<TFieldProperties extends IFieldProperties>(name: string): TFieldProperties | undefined {
        var fieldProperties = this.fieldProperties?.filter(s => s.name == name);

        if (!fieldProperties) {
            return undefined;
        }

        return fieldProperties[0] as TFieldProperties;
    }
    resetFieldProperties() {
        if (this.fieldProperties) {
            for (var tt=1; tt<= this.fieldProperties.length; tt++) {
                this.fieldProperties[tt-1].resetValue();
            }
        }
    }
}

export const FormContext = React.createContext<FormContextProperties | undefined>(undefined);

interface FormProperties {
    fieldProperties: IFieldProperties[];
    submitLabel: string;
    submitApiEndpoint: string;
    container: JSX.Element|JSX.Element[];
    thankYouContainer?: JSX.Element|JSX.Element[];
    onSubmitCallback?: ((json: any) => void);
    onRedirectCallback?: ((json: any) => void);
}

const Form: React.FC<FormProperties> = (formProperties: FormProperties) => {

    const formValidationErrorText: JSX.Element = <>Please correct the issues highlighted and then re-submit your request.</>;
    const formSubmissionErrorText: JSX.Element = <>We seem to be having some technical issues submitting your request. Please try again.</>;

    const modalContext = useContext(ModalContext);    
    const formContext = new FormContextProperties({ fieldProperties: formProperties.fieldProperties });

    const [state, setState] = useState<{valid?: boolean, loading: boolean, submitted: boolean, errorMessage?: JSX.Element}>({
        valid: undefined,
        loading: false,
        submitted: false
    });

    useEffect(() => {
        if (formContext.fieldProperties) {
            for (var tt=1; tt<= formContext.fieldProperties.length; tt++) {
                var fieldProperties = formContext.fieldProperties[tt-1];
        
                fieldProperties.valid = undefined;
                fieldProperties.errorMessage = undefined;
                fieldProperties.refreshComponentState();

                fieldProperties.onValidChange = () => {
                    var formValid = isValid(false);

                    setState({valid: formValid, loading: state.loading, submitted: state.submitted, errorMessage: (!formValid ? formValidationErrorText : undefined) });
                }
            }
        }
        
        if (modalContext && modalContext.setResetForm) {
            modalContext.setResetForm(() => {
                // Called in the modal with pop state
                setState({valid: undefined, loading: false, submitted: false, errorMessage: undefined });                     
            });
        }    
    }, []);
    
    const isValid = (validate: boolean) => {
        var formValid = true;

        if (formContext.fieldProperties) {
            for (var tt=1; tt<= formContext.fieldProperties.length; tt++) {
                var fieldProperties = formContext.fieldProperties[tt-1];

                if (validate) {
                    // Validate field (only happens if submitting)
                    fieldProperties.validate();
                }
                if (fieldProperties.valid === false) {
                    // Form is not valid is the field isn't.
                    formValid = false;
                }
            }
        }

        return formValid;
    }


    const formSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        var options = GetSiteOptions();
        var formValid = isValid(true);

        if (formValid) {
            // Submit the form and redirect to thank you page.
            setState({ valid: formValid, loading: true, submitted: state.submitted, errorMessage: undefined });

            var values: any = {};

            if (formContext.fieldProperties) {
                for (var tt=1; tt<= formContext.fieldProperties?.length; tt++) {
                    var fieldProperties = formContext.fieldProperties[tt-1];
                    
                    values[fieldProperties.name] = fieldProperties.value;
                }
            }

            // Send tracking to website.

            fetch(`${formProperties.submitApiEndpoint}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            })
            .then(async(response) => {
                let json:any = null;
                
                try {
                    json = await response.json();
                }
                catch(exception) {

                }

                var formResponse: FormResponse = {response:response, json: json};

                if (!formResponse.response || !formResponse.response.status) {
                    // Throw error if no response.
                    return formResponse;
                }

                if (formContext.fieldProperties) {
                    for (var tt=1; tt<= formContext.fieldProperties?.length; tt++) {
                        var fieldProperties = formContext.fieldProperties[tt-1];

                        if (json && json["errors"] && json["errors"][fieldProperties.name] && Array.isArray(json["errors"][fieldProperties.name])) {
                            var errors: string[] = json["errors"][fieldProperties.name];

                            var errorMessageBuilder: JSX.Element[] = [];

                            errors.forEach(error => {
                                if (errorMessageBuilder.length > 0) {
                                    errorMessageBuilder.push(<><br /></>);
                                }

                                errorMessageBuilder.push(<>{error}</>);
                            });

                            if (errorMessageBuilder.length > 0) {
                                fieldProperties.setErrorMessage(<>{errorMessageBuilder}</>);
                            }
                        }                        
                    }
                }

                if (!response.ok) {
                    // Throw the error response.
                    return formResponse;
                }

                // Update URL
                var currentPage = GetPage(GetCurrentUrl());

                if (currentPage) {
                    if (modalContext != null) {
                        if (modalContext.onStateChange) {
                            modalContext.onStateChange();
                        }
                        PushHistoryState(new PageProperties({ path: currentPage.path + (!currentPage.isThankYou ? "/thank-you" : ""), querystring: currentPage.essentialQuerystring }).pathAndEssentialQuery);        
                    }
                }        
                
                return formResponse;
            })
            .then((formResponse: FormResponse | false) => {                
                
                if (!formResponse || !formResponse.response) {
                    CaptureSentryException("The endpoint '" + formProperties.submitApiEndpoint + "' did not return a response.");

                    setState({ valid: undefined, loading: false, submitted: false, errorMessage: formSubmissionErrorText });  
                    return false;
                }
                if (!formResponse.response?.ok) {
                    var validationError = formResponse.json && formResponse.json["errors"];

                    if (!validationError) {
                        CaptureSentryException("The endpoint '" + formProperties.submitApiEndpoint + "' threw an error: (" + formResponse.response.status + ") " + formResponse.response.statusText);
                    }

                    var errorMessage = validationError ? formValidationErrorText : formSubmissionErrorText;
                    if (formResponse.json && formResponse.json["errorMessage"]) {
                        errorMessage = <>{formResponse.json["errorMessage"].toString()}</>;
                    }

                    setState({ valid: undefined, loading: false, submitted: false, errorMessage: errorMessage });  
                    return false;
                }  
                
                // No errors - Reset form fields.
                formContext.resetFieldProperties();
                
                if (formResponse.json && formResponse.json["redirectUrl"]) { 

                    // Call redirect handler
                    if (formProperties.onRedirectCallback) {
                        formProperties.onRedirectCallback(formResponse.json);
                    }

                    // Redirect user.
                    document.location.href = formResponse.json["redirectUrl"].toString();
                    return true;
                }

                // On submit handler
                if (formProperties.onSubmitCallback) {
                    formProperties.onSubmitCallback(formResponse.json);
                }
                
                // Show thank you page
                setState({ valid: undefined, loading: false, submitted: true, errorMessage: undefined });
                return true;
            })
            .catch(() => {
                setState({ valid: undefined, loading: false, submitted: false, errorMessage: formSubmissionErrorText });                
            });
        }
        else {
            setState({ valid: formValid, loading: state.loading, submitted: state.submitted, errorMessage: formValidationErrorText });
        }
    }

    const hideModal = () => {
        if (modalContext && modalContext.onHide) {
            modalContext.onHide();
        }
    }

    // Show loading modal if the form is not in a modal.
    if (!modalContext) {
        var loadingModal = document.querySelector("div[data-loading-modal-container]") as HTMLDivElement;

        if (loadingModal) {
            if (state.loading) {
                AddClass(loadingModal, "show-no-fade");
            }
            else {
                RemoveClass(loadingModal, "show-no-fade");
            }
        }
    }

    var loader = <Loader></Loader>

    return <FormContext.Provider value={ formContext }>
        {state.loading &&
            <>
            {modalContext ? <>{loader}</>  : <></>}
            </>
        }
        {!state.submitted &&
            <>
            <div className="container form-container">
                {state.errorMessage && 
                    <div className="alert-bar">
                        <div className="alert alert-danger">
                            {state.errorMessage}
                        </div>
                    </div>
                }                 
                <div className="row">
                    {formProperties.container}
                </div>
                <div className="row form-submit">
                    <button type="submit" className="btn btn-primary" onClick={formSubmit}>{formProperties.submitLabel}</button>
                </div>
            </div>
            </>
        }
        {state.submitted && formProperties.thankYouContainer &&
            <>
            <div className="container form-container">
                <div className="form-success">
                    <div className="form-padding alert-bar alert-bar-success">
                        <div className="alert alert-success"><>{formProperties.thankYouContainer}</></div>
                    </div>   
                </div>               
                {modalContext && 
                    <div className="row form-submit">
                        <button type="submit" className="btn btn-primary" onClick={hideModal}>Close</button>
                    </div>
                } 
            </div>
            </>  
        }
        </FormContext.Provider>
}
export default Form;
