import { SiteOptions } from "./siteOptions";

const ExternalLinks = (options?: SiteOptions) => {
    if (!options) {         
        return;
    }

    var allLinks: HTMLCollectionOf<HTMLAnchorElement | HTMLLinkElement> = document.getElementsByTagName("a");
    
    for (var tt=1; tt<= allLinks.length; tt++) {
        let link = allLinks[tt-1], href = link.hasAttribute("href") ? link.getAttribute("href")!.toString() : null;

        if (href?.match(/^(http(s)?:\/\/([^\/]+))(\/.*)?$/ig)) {
            var domain = href.replace(/^(http(s)?:\/\/([^\/]+))(\/.*)?$/ig, "$1");

            if (domain != options.host && !domain.match(/translate\.goog/ig)) {
                // Outbound link, so open in new window.
                link.target = "_blank";
            }
        } 
    }   
}

export { ExternalLinks }